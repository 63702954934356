.about-form {
    background-color: white;
    padding: 50px 50px;
}

.about-form-h2 {
    font-size: 50px;
    font-family: "Le Jeune Stencil";
    margin-top: 70px;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: lowercase;
}

.about-form-p {
    font-family: "Futura";
    margin-bottom: 30px;
    text-align: justify;
    letter-spacing: 0.1em;
    text-transform: lowercase;
    line-height: 1.5;
}


@media screen and (max-width: 768px) {
    .about-form {
        padding: 30px 30px;
    }

    .about-form-h2 {
        font-size: 35px;
    }
}