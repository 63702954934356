.home-img {
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.hero-container>h1 {
    font-family: "Le Jeune Stencil";
    max-width: 90vw;
    letter-spacing: 0.1em;
    color: #C3C5C7;
    font-size: 110px;
    margin-top: -100px;
    animation: animation 5s infinite linear;
    text-shadow:
        3px 3px 5px rgba(0, 0, 0, 0.5),
        -2px -2px 2px rgba(255, 255, 255, 0.5);
}

@keyframes animation {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.9;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 0;
    }
}

.hero-container>p {
    margin-top: 8px;
    color: #C3C5C7;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 80px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        text-align: center;
        font-size: 70px;
        margin-top: -70px;
        margin-bottom: 30px;
    }

    .hero-container>p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .logo-hero {
        margin-top: 10px;
    }
}

.logo-hero {
    width: 10%;
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}