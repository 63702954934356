.header-container {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.header-img {
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    position: fixed;
    z-index: -1;
    object-fit: cover;
}

.header-container>h1 {
    margin-top: -10%;
    font-family: "Le Jeune Stencil";
    max-width: 90vw;
    letter-spacing: 0.1em;
    color: #fff;
    font-size: 80px;
    text-shadow:
        3px 3px 5px rgba(190, 190, 190, 0.5),
        -2px -2px 2px rgba(255, 255, 255, 0.5);
}


@media screen and (max-width: 960px) {
    .header-container>h1 {
        font-size: 70px;
        margin-top: -50%;
    }

    .header-container {
        height: 90vh;
    }
}

@media screen and (max-width: 768px) {
    .header-containerr>h1 {
        text-align: center;
        font-size: 50px;
    }

    .header-container {
        height: 90vh;
    }
}